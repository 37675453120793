/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpParams
} from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { filter } from "rxjs/operators/filter";
import { TicketReprint } from "../models";

@Injectable()
export class ManagerTicketReprintService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

    managerlistTicketReprintResponse(
      params: TicketReprint
    ): Observable<HttpResponse<any[]>> {
      let __params = this.newParams();
      let __headers = new HttpHeaders();
      let __body: any = null;
      if (params.from_date != null)
        __params = __params.set("from_date", params.from_date.toString());
      if (params.to_date != null)
        __params = __params.set("to_date", params.to_date.toString());
      if (params.page != null)
        __params = __params.set("page", params.page.toString());
      if (params.limit != null)
        __params = __params.set("limit", params.limit.toString());
      if (params.ticket_price_id != null)
        __params = __params.set(
          "ticket_price_id",
          params.ticket_price_id.toString()
        );
      let req = new HttpRequest<any>(
        "GET",
        this.rootUrl + `/manager/ticket-reprint`,
        __body,
        {
          headers: __headers,
          params: __params,
          responseType: "json"
        }
      );

      return this.http.request<any>(req).pipe(
        filter(_r => _r instanceof HttpResponse),
        map(_r => {
          let _resp = _r as HttpResponse<any>;
          let _body: any[] = null;
          _body = _resp.body as any[];
          return _resp.clone({ body: _body }) as HttpResponse<
          any[]
          >;
        })
      );
    }

    /**
     */
    managerlistTicketReprint(
      params: TicketReprint
    ): Observable<any[]> {
      return this.managerlistTicketReprintResponse(params).pipe(
        map(_r => _r.body)
      );
    }

    managerDownloadTicketReprintResponse(
      params: TicketReprint
    ): Observable<HttpResponse<string>> {
      let __params = this.newParams();
      let __headers = new HttpHeaders();
      let __body: any = null;
  
      if (params.from_date != null)
        __params = __params.set("from_date", params.from_date.toString());
      if (params.to_date != null)
        __params = __params.set("to_date", params.to_date.toString());
  
      if (params.ticket_price_id != null)
        __params = __params.set(
          "ticket_price_id",
          params.ticket_price_id.toString()
        );
      let req = new HttpRequest<any>(
        "GET",
        this.rootUrl + `/manager/ticket-reprint/export`,
        __body,
        {
          headers: __headers,
          params: __params,
          responseType: "blob"
        }
      );
      return this.http.request<any>(req).pipe(
        filter(_r => _r instanceof HttpResponse),
        map(_r => {
          let _resp = _r as HttpResponse<any>;
          let _body: string = null;
          _body = _resp.body as string;
          return _resp.clone({ body: _body }) as HttpResponse<string>;
        })
      );
    }
}
